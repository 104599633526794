/**
 * @generated SignedSource<<324474ba72c79f6c0339b9d19c0f2f59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditProjectInput = {
  clientMutationId?: string | null;
  data: ProjectDataInput;
  moveAssignmentEndDates: boolean;
  moveAssignmentStartDates: boolean;
  projectId: string;
};
export type ProjectDataInput = {
  address?: AddressWithGps | null;
  architectName?: string | null;
  avatarId?: string | null;
  budgetedLaborCosts?: number | null;
  clientName?: string | null;
  comments?: string | null;
  divisionId?: string | null;
  endDate?: any | null;
  generalConditionsPercentage?: number | null;
  milestones: ReadonlyArray<MilestoneInput | null>;
  name: string;
  projectIdentifier?: string | null;
  regionId?: string | null;
  skillsIds?: ReadonlyArray<string> | null;
  stageId: string;
  startDate?: any | null;
  volume?: number | null;
  weight?: number | null;
};
export type AddressWithGps = {
  city: string;
  country: string;
  latitude?: number | null;
  lineOne: string;
  longitude?: number | null;
  postalCode: string;
  state: string;
};
export type MilestoneInput = {
  date: any;
  name: string;
};
export type editProjectButton_EditMutation$variables = {
  input: EditProjectInput;
};
export type editProjectButton_EditMutation$data = {
  readonly Project: {
    readonly editProject: {
      readonly changedAssignments: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"AssignmentProjectCard_AssignmentFragment">;
      }>;
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"editProjectButton_ProjectFragment">;
        };
      };
    } | null;
  };
};
export type editProjectButton_EditMutation = {
  response: editProjectButton_EditMutation$data;
  variables: editProjectButton_EditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v7 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editProjectButton_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectMutations",
        "kind": "LinkedField",
        "name": "Project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditProjectPayload",
            "kind": "LinkedField",
            "name": "editProject",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectsEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editProjectButton_ProjectFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Assignment",
                "kind": "LinkedField",
                "name": "changedAssignments",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AssignmentProjectCard_AssignmentFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editProjectButton_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectMutations",
        "kind": "LinkedField",
        "name": "Project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditProjectPayload",
            "kind": "LinkedField",
            "name": "editProject",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectsEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lineOne",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "city",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "postalCode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "state",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "country",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "latitude",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "longitude",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "source",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "architectName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clientName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectStage",
                        "kind": "LinkedField",
                        "name": "stage",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "skills",
                        "plural": true,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "volume",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "generalConditionsPercentage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "budgetedLaborCosts",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Division",
                        "kind": "LinkedField",
                        "name": "division",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Region",
                        "kind": "LinkedField",
                        "name": "region",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectIdentifier",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "durationInMonths",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Milestone",
                        "kind": "LinkedField",
                        "name": "milestones",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "date",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comments",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Assignment",
                "kind": "LinkedField",
                "name": "changedAssignments",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDeactivated",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "person",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentRole",
                    "kind": "LinkedField",
                    "name": "validAssignmentRoles",
                    "plural": true,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fd6ea50928391c927bb5404b9781a33e",
    "id": null,
    "metadata": {},
    "name": "editProjectButton_EditMutation",
    "operationKind": "mutation",
    "text": "mutation editProjectButton_EditMutation(\n  $input: EditProjectInput!\n) {\n  Project {\n    editProject(input: $input) {\n      edge {\n        node {\n          id\n          ...editProjectButton_ProjectFragment\n        }\n      }\n      changedAssignments {\n        ...AssignmentProjectCard_AssignmentFragment\n        id\n      }\n    }\n  }\n}\n\nfragment AssignmentProjectCard_AssignmentFragment on Assignment {\n  id\n  project {\n    name\n    id\n    isDeactivated\n  }\n  person {\n    name\n    id\n  }\n  startDate\n  endDate\n  validAssignmentRoles {\n    id\n    name\n  }\n}\n\nfragment editProjectButton_ProjectFragment on Project {\n  id\n  name\n  startDate\n  endDate\n  address {\n    lineOne\n    city\n    postalCode\n    state\n    country\n    latitude\n    longitude\n  }\n  source\n  architectName\n  clientName\n  stage {\n    id\n    name\n  }\n  skills {\n    id\n  }\n  volume\n  generalConditionsPercentage\n  budgetedLaborCosts\n  division {\n    id\n  }\n  region {\n    id\n  }\n  projectIdentifier\n  durationInMonths\n  avatar {\n    id\n    url\n  }\n  milestones {\n    name\n    date\n  }\n  comments\n}\n"
  }
};
})();

(node as any).hash = "2af66254a1ee8a0346610856ceaa777b";

export default node;
